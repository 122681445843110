<template>
  <v-app>
    <v-card class="elevation-0" align="center">
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" align="center">
            <v-card height="400px" outlined>
              <v-toolbar dark dense color="red" class="elevation-0"><h1>Error Occured!!!</h1> </v-toolbar>
              <v-card-text>
                <v-img src="@/assets/ErrorPage.jpg" max-width="400px"></v-img>
                <h2 class="mt-4">Something Went Wrong !!!</h2>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    closeWindow() {
      window.close();
    },
  },
};
</script>
